import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

import Layout from '../components/Layout';
import SectionFeature from '../components/sections/SectionFeature';
import SectionEarlyAccess from '../components/sections/SectionEarlyAccess';

import useResizeScreen from '../hooks/useResize';

import iconIntegration from '../assets/images/icons/general-svg/icon-integration.svg';

const CamelStudioPage = () => {
  const { isMobile } = useResizeScreen();
  return (
    <Layout title="Jetic - Camel Studio for low-code API & Integration Design">
      <div className="visual-builder-page">
        <section className="first-section first-section--common">
          <div className="container pt-4">
            <div className="row">
              <div className="col-md-6">
                <div className="first-section__content">
                  <h1 className="header-700 mb-4">Camel Studio</h1>
                  <h2 className="body-300 gray-500 mb-32">
                    Low-code browser-based IDE to quickly build and test powerful Integrations and
                    APIs.
                  </h2>
                  <div className="btn-wrapper mb-3 pb-3">
                    <a
                      href="https://app.jetic.io/registration"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="c-btn c-btn--primary"
                    >
                      Sign up
                    </a>
                    <a
                      href="https://docs.jetic.io/docs/InstallationGuide/quickstart"
                      className="c-btn c-btn--white"
                    >
                      Quickstart Guide
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {!isMobile ? (
                  <StaticImage
                    src="../assets/images/pages/visual-builder/Illustration-main.png"
                    className="first-section__image illustration w-100"
                    alt="Camel Studio"
                  />
                ) : (
                  <StaticImage
                    src="../assets/images/pages/visual-builder/Illustration-main--mobile.png"
                    className="first-section__image illustration w-100"
                    alt="Camel Studio"
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="c-section--primary">
          <div className="container">
            <div className="row">
              <h3 className="header-600 mb-4">Become a Rapid Prototyper</h3>
              <div className="col-md-5">
                <ul className="list-disc body-300">
                  <li>Seamlessly build powerful integrations without coding</li>
                  <li>Up and running on your Kubernetes in seconds</li>
                  <li>Push Apache Camel DSL to any Git repository</li>
                </ul>
              </div>
              <div className="col-md-6 offset-md-1 mt-4 mt-md-0">
                <p className="body-300">
                  With developers and practitioners in mind our Camel Studio is the quickest way to
                  build Integrations. Implement APIs, connect systems, and transform data at
                  warp-speed. Our real-time data analyzer and log viewer let's you see your results
                  instantly.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="c-section--white">
          <div className="container">
            <SectionFeature
              sectionClassName="pb-80"
              contentLabelText="Build"
              contentTitleText="Point-and-click Route Builder"
              contentBodyTags={
                <>
                  <p className="body-300 gray-400 mb-4">
                    Without coding use Apache Camel's massive library capabilities and seamlessly
                    utilize over 300+ connectors, 50+ integration patterns and 25+ data formats. If
                    needed, we allow you to add your own code and libraries not limiting the
                    developer's freedom.
                  </p>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/visual-builder/Illustration-route-builder.png"
                    alt="Point-and-click Route Builder"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/visual-builder/Illustration-route-builder--mobile.png"
                    alt="Point-and-click Route Builder"
                  />
                ),
              }}
            />

            <SectionFeature
              sectionClassName="py-80"
              contentLabelText="Generate"
              contentTitleText="Engineering on us. For you."
              contentBodyTags={
                <>
                  <p className="body-300 gray-400 mb-4">
                    Our backend generates the Camel DSL code and applies all necessary
                    configurations and traits. Once ready, you can push the code to your Git
                    repository and follow any standardized DevOps processes.
                  </p>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/visual-builder/Illustration-engineering.png"
                    alt="Engineering on us. For you."
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/visual-builder/Illustration-engineering--mobile.png"
                    alt="Engineering on us. For you."
                  />
                ),
              }}
            />

            <SectionFeature
              sectionClassName="py-80"
              contentLabelText="Run"
              contentTitleText="No more holding patterns"
              contentBodyTags={
                <>
                  <p className="body-300 gray-400 mb-4">
                    It only takes seconds to build a Docker container and run the integrations
                    directly in your Kubernetes. Simply select the desired cluster and press Play.
                    Changes are handled incrementally for quick turnaround. We want you to get your
                    work done fast.
                  </p>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/visual-builder/Illustration-holding-patterns.png"
                    alt="No more holding patterns"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/visual-builder/Illustration-holding-patterns--mobile.png"
                    alt="No more holding patterns"
                  />
                ),
              }}
            />

            <SectionFeature
              sectionClassName="pt-80"
              contentLabelText="Improve"
              contentTitleText="Watch your data flowing"
              contentBodyTags={
                <>
                  <p className="body-300 gray-400 mb-4">
                    Track and trace your data flowing through your integrations in real-time. No
                    need to add log statements or setup complicated debug instructions. Instead get
                    clarity instantly and tweak your integrations until you are truly satisfied.
                  </p>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/visual-builder/Illustration-data-flowing.png"
                    alt="Watch your data flowing"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/visual-builder/Illustration-data-flowing--mobile.png"
                    alt="Watch your data flowing"
                  />
                ),
              }}
            />
          </div>
        </section>

        <section className="c-section--gray py-80">
          <div className="container">
            <h3 className="header-600 text-center mb-0">
              Building open-source Integrations and APIs has never been easier
            </h3>
          </div>
        </section>

        <section className="py-80" style={{ boxShadow: 'inset 0px -1px 0px #E8E9EA' }}>
          <div className="container">
            <div className="row mb-80">
              <div className="col-lg-10 mx-auto">
                {!isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/visual-builder/Illustration-vb.png"
                    alt="Build.Run.Inspect"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/visual-builder/Illustration-vb--mobile.png"
                    alt="Build.Run.Inspect"
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pe-lg-5">
                <h4 className="header-500 mb-4">Build</h4>
                <ul className="list-check body-300 gray-400">
                  <li>Easy-to-use drag and drop generator for Apache Camel integrations</li>
                  <li>API Builder to expose standardized REST APIs </li>
                  <li>300+ pre-built components</li>
                  <li>
                    Connect to Kafka, JMS, SQL, FTP, S3, Salesforce, SAP and many more enterprise
                    systems
                  </li>
                  <li>
                    Easy data transformation with over 50+ supported data format as XML, JSON, HL7,
                    CSV
                  </li>
                  <li>Manage complex routing rules and handle transaction</li>
                  <li>Manage exception scenarios</li>
                  <li>Plugin custom code and Java Libraries</li>
                </ul>
              </div>
              <div className="col-md-6 pe-lg-5">
                <h4 className="header-500 mb-4 mt-4 mt-md-0">Run</h4>
                <ul className="list-check body-300 gray-400">
                  <li>One-click Docker Container Creation and deployment to Kubernetes</li>
                  <li>Deploy on your AWS EKS, Azure AKS, Google GKE or any other cloud K8</li>
                  <li>Deploy on any on-premises Kubernetes cluster or Minikube</li>
                </ul>

                <h4 className="header-500 mb-4 mt-4 mt-md-5">Inspect</h4>
                <ul className="list-check body-300 gray-400">
                  <li>Instantly monitor data exchanges from our Data Analyzer</li>
                  <li>Access builder & runtime pod logs in real-time</li>
                  <li>
                    Applied changes to integrations, traits and parameters get reflected in no-time
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="py-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="row">
                  <div
                    className={classNames('col-md-6 d-flex align-items-center', {
                      'flex-wrap': isMobile,
                      'justify-content-center': isMobile,
                    })}
                  >
                    {isMobile && (
                      <img
                        className="mb-4"
                        src={iconIntegration}
                        alt="From Idea to Integrated in Minutes"
                        height={48}
                        width={48}
                      />
                    )}
                    <h3
                      className={classNames('header-600', {
                        'text-center': isMobile,
                      })}
                    >
                      From Idea to Integrated in Minutes
                    </h3>
                  </div>
                  {!isMobile && (
                    <div className="col-md-6">
                      <StaticImage
                        className="illustration"
                        src="../assets/images/pages/visual-builder/Illustration-from-idea.png"
                        alt="Build.Run.Inspect"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <SectionEarlyAccess />
      </div>
    </Layout>
  );
};

export default CamelStudioPage;
